import React from "react"
import FormLabel from "../../atoms/FormLabel"
import FormError from "../../atoms/FormError"

import s from "./FormSelect.module.scss"

const FormSelect = ({
    label,
    isRequired,
    field,
    form: { touched, errors, setFieldValue, handleBlur, values },
    options,
}) => {
    const hasError = touched[field.name] && errors[field.name] && true

    const handleOnChange = e => {
        e.preventDefault()
        setFieldValue(field.name, e.target.value)
    }

    return (
        <div className={`${s.formControl} ${hasError ? s.fieldError : ""}`}>
            <FormLabel
                invalidState={hasError}
                label={label}
                isRequired={isRequired}
                forName={field.name}
            />
            <select
                name={field.name}
                value={values[field.name] || options[0]}
                className={s.formSelect}
                onBlur={handleBlur}
                onChange={handleOnChange}
            >
                {options.map((option, index) => (
                    <option key={`subject-${index}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            {hasError && <FormError errorMessage={errors[field.name]} />}
        </div>
    )
}

export default FormSelect
