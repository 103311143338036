import React from "react"
import FormLabel from "../../atoms/FormLabel"
import FormError from "../../atoms/FormError"

import s from "./FormInput.module.scss"

const FormInput = ({
    label,
    isRequired,
    field,
    form: { touched, errors, values, initialValues, handleChange },
    type = "text",
}) => {
    const hasError = touched[field.name] && errors[field.name] && true

    return (
        <div className={`${s.formControl} ${hasError ? s.fieldError : ""}`}>
            <FormLabel
                invalidState={hasError}
                label={label}
                isRequired={isRequired}
                forName={field.name}
            />
            <input
                id={field.name}
                value={values[field.name] || initialValues[field.name]}
                className={s.formInput}
                onChange={handleChange}
                type={type}
                {...field}
            />
            {hasError && <FormError errorMessage={errors[field.name]} />}
        </div>
    )
}

export default FormInput
