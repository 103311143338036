import React, { Fragment } from "react"
import { Field, Form, withFormik } from "formik"
import FormInput from "../../molecules/FormInput"
import FormSelect from "../../molecules/FormSelect"
import FormTextArea from "../../molecules/FormTextArea"
import { fields } from "./config"
import ContactUsFormSchema from "./validation"
import Button from "../../atoms/Button"
import { sendContactUsMessage } from "../../../services/emailService"

import s from "./ContactUsForm.module.scss"

const InnerContactUsFormForm = ({ handleSubmit, isSubmitting }) => (
    <Fragment>
        <Form id="contact-us-form" type="submit" className={s.contactUsWrapper}>
            <div className={s.formColumn}>
                {fields.map((field, index) => {
                    if (index < 3) {
                        return (
                            <Field
                                type={field.type}
                                name={field.name}
                                key={index.toString()}
                                {...field}
                                component={FormInput}
                            />
                        )
                    }
                })}
            </div>
            <div className={s.formColumn}>
                {fields.map((field, index) => {
                    if (index >= 3) {
                        return (
                            <Field
                                type={field.type}
                                name={field.name}
                                key={index.toString()}
                                {...field}
                                component={
                                    field.name === "message"
                                        ? FormTextArea
                                        : FormSelect
                                }
                            />
                        )
                    }
                })}
            </div>
        </Form>
        <div className={s.meta}>
            <p>* = required</p>
            <div className={s.submitButton}>
                <Button
                    type="submit"
                    colour="Primary"
                    form="contact-us-form"
                    onClick={handleSubmit}
                >
                    {isSubmitting ? (
                        <i className="fas fa-spinner fa-spin" />
                    ) : (
                        "Continue"
                    )}
                </Button>
            </div>
        </div>
    </Fragment>
)

const ContactUsForm = withFormik({
    mapPropsToValues: () => ({
        name: "",
        email: "",
        telephone: "",
        subject: "",
        message: "",
    }),
    validateOnChange: false,
    validationSchema: ContactUsFormSchema,
    handleSubmit: (values, { resetForm, setSubmitting, setStatus }) => {
        sendContactUsMessage(
            values,
            () => setSubmitting(false),
            resetForm({
                name: "",
                email: "",
                telephone: "",
                subject: "",
                message: "",
            }),
            setStatus({ success: true })
        )
    },
    displayName: "ContactUsForm",
})(InnerContactUsFormForm)

export default ContactUsForm
