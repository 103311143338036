import Axios from "axios"

export const sendContactUsMessage = (emailData, cb) => {
    Axios.post(
        "https://w9o7srey97.execute-api.eu-west-1.amazonaws.com/prod/contact",
        {
            subject: emailData.subject,
            name: emailData.name,
            email: emailData.email,
            telephone: emailData.telephone,
            message: emailData.message,
        },
        {
            headers: {
                "x-api-key": "PtCNRXXSQ1ZtRwp1Gong8VggERMj9dR4TiF9Qhh6",
            },
        }
    )
        .then(response => {
            if (response.status === 200) {
                cb()
            }
        })
        .catch(error => console.log(error))
}
