import React from "react"

import s from "./Button.module.scss"

function Button(props) {
    return (
        <button
            className={s[`button${props.colour}`]}
            type="button"
            disabled={props.disabled}
            form={props.form ? props.form : ""}
            onClick={e => (props.onClick ? props.onClick(e) : {})}
        >
            {props.children}
        </button>
    )
}

export default Button
