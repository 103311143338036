import React from "react"
import s from "./FormError.module.scss"

const FormError = ({ errorMessage }) => (
    <div className={s.errorContainer}>
        <p className={`flex-col-12 ${s.errorMessage}`}>{errorMessage}</p>
    </div>
)

export default FormError
