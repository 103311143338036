import * as Yup from "yup"

const ContactUsFormSchema = Yup.object().shape({
    name: Yup.string().required("We require your name"),
    email: Yup.string()
        .email("Valid email required")
        .required("Valid email required"),
    telephone: Yup.number().required("A phone number is required"),
    subject: Yup.string().required("Please select a subject for your message"),
    message: Yup.string().required("Please provide us with some content"),
})

export default ContactUsFormSchema
