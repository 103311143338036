export const fields = [
    {
        name: "name",
        label: "Name",
        isRequired: true,
        type: "text",
    },
    {
        name: "email",
        label: "Email",
        isRequired: true,
        type: "text",
    },
    {
        name: "telephone",
        label: "Telephone Number",
        isRequired: true,
        type: "text",
    },
    {
        name: "subject",
        label: "Subject",
        isRequired: true,
        options: [
            "-- Please choose a subject --",
            "Call back",
            "Quote",
            "Enquiry",
            "Other",
        ],
    },
    {
        name: "message",
        label: "Message",
        isRequired: true,
        type: "textarea",
    },
]
