import React from "react"
import s from "./FormLabel.module.scss"

const FormLabel = ({
    forName,
    label,
    isRequired = null,
    invalidState = false,
    requiredText = " *",
}) => (
    <label
        data-testid="form-label"
        htmlFor={forName}
        className={`${s.formLabel} ${invalidState ? s.fieldError : ""}`}
    >
        {label}
        {isRequired && <span className={s.isRequired}>{requiredText}</span>}
    </label>
)

export default FormLabel
