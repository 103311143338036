import React from "react"
import s from "./contact_us.module.scss"
import Layout from "../layout"
import Heading from "../../atoms/Heading"
import Subheading from "../../atoms/Subheading"
import ContactUsForm from "../../organisms/ContactUsForm"
import ContentContainer from "../../atoms/ContentContainer"

function ContactUs({ pageContext }) {
    return (
        <Layout>
            <ContentContainer>
                <div className={s.pageIntro}>
                    <Heading>Contact Us</Heading>
                    <Subheading>{pageContext.description}</Subheading>
                    <hr />
                    <ContactUsForm />
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default ContactUs
