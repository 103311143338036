import React from "react"
import FormLabel from "../../atoms/FormLabel"
import FormError from "../../atoms/FormError"

import s from "./FormTextArea.module.scss"

const FormTextArea = ({
    label,
    isRequired,
    field,
    form: { touched, errors, setFieldValue, handleBlur, values },
}) => {
    const hasError = touched[field.name] && errors[field.name] && true

    const handleOnChange = e => {
        e.preventDefault()
        setFieldValue(field.name, e.target.value)
    }

    return (
        <div className={`${s.formControl} ${hasError ? s.fieldError : ""}`}>
            <FormLabel
                invalidState={hasError}
                label={label}
                isRequired={isRequired}
                forName={field.name}
            />
            <textarea
                name={field.name}
                value={values[field.name] || ""}
                className={s.formTextArea}
                onBlur={handleBlur}
                onChange={handleOnChange}
                cols="40"
                rows="4"
            />
            {hasError && <FormError errorMessage={errors[field.name]} />}
        </div>
    )
}

export default FormTextArea
